import logo from '../assets/images/logo.svg';
import {useReducer} from "react";
import {useParams} from "react-router-dom";
import {changePassword} from "../Core/Api";

function getBody({ onSubmit, isLoading, result, message }) {
    if (isLoading) {
        return (
            <h1 className="verify__activity_title">
                Please, wait a bit...
            </h1>
        )
    }
    if (result && result.isSuccess) {
        return (
            <h1 className="verify__activity_title">
                {result.message}
            </h1>
        )
    }
    return (
        <>
            <h1 className="verify__activity_title">
                Change password
            </h1>
            <p className="verify__activity_text">
                Please, enter a new password
            </p>
            <form onSubmit={onSubmit}>
                <div className="fields">
                    <label>
                        <input className="field" placeholder="New password" type="password" minLength="7" />
                    </label>
                    <label>
                        <input className="field" placeholder="Verify password" type="password" minLength="7" />
                    </label>
                    {message && <p className='verify__error-text'>
                        {message}
                    </p>}
                    {result && !result.isSuccess && <p className='verify__error-text'>
                        {result.message}
                    </p>}
                    <button type="submit" className="fields_btn">
                        <p>Change password</p>
                    </button>
                </div>
            </form>

        </>
    )
}

export const ChangePasswordPage = () => {
    const {userKey, expirationKey} = useParams()
    const [state, dispatchState] = useReducer((state, action) => {
        if (action.type === 'MESSAGE') {
            return { ...state, message: action.payload }
        } else if (action.type === 'RESULT') {
            return { ...state, result: action.payload, isLoading: false, message: null }
        } else if (action.type === 'LOADING') {
            return { ...state, isLoading: true, result: null, message: null }
        }
    }, {}, () => {
        return {
            isLoading: false,
            message: null,
            result: null
        }
    })
    const onSubmit = e => {
        e.preventDefault()
        const password = e.target[0].value
        const verifyPassword = e.target[1].value
        if (password !== verifyPassword) {
            dispatchState({ type: 'MESSAGE', payload: 'Passwords does not match' })
            return
        }
        dispatchState({ type: 'LOADING' })
        changePassword(userKey, expirationKey, password, undefined)
            .then(e => dispatchState({ type: 'RESULT', payload: e }))
            .catch(e => dispatchState({ type: 'RESULT', payload: { isSuccess: false, message: 'Something went wrong' } }))
    }
    return (
        <main className="main">
            <section className="container">
                <header className="header">
                    <img
                        src={logo}
                        alt="Logo"
                        className="logo__img"
                    />
                    <p>GoViral</p>
                </header>
                <section className="wrapper">
                    <div className="about__activity">
                        {getBody({ onSubmit, isLoading: state.isLoading, message: state.message, result: state.result })}
                    </div>
                </section>
            </section>
        </main>
    )
}